


























































































import {Component, Vue} from "vue-property-decorator";
import SingleStoreCardGiveAPI from "@/common/api/singleStoreCardGive";
import {getPageAPIs, Util} from "@/common/util";
export const pageApiList = getPageAPIs(SingleStoreCardGiveAPI);
const defaultAddForm = {

}
@Component({})
export default class UserList extends Vue {
  private classApi: any = new SingleStoreCardGiveAPI();
  private add_form: any = {
    phone: '',
    price: '',
    recharge:'',
    remark:''
  };
  private buttonLoading:boolean = false
  private form: any = {
    phone: '',
  };
  private  checkRecharge = (rule, value, callback) => {
    console.log(this.add_form,this.add_form.recharge)
    if(!value){
      callback(new Error('充值金额必填！'));
    }else {
      callback();
    }
  };

  private rules:any =  {
    phone: [
      { required: true, message: '请输入手机号', trigger: 'blur' },
    ],
    price: [
      { required: true, message: '赠送金额必填', trigger: 'blur' },
    ],
    recharge: [
      { required: true, validator: this.checkRecharge,trigger: 'change' },
    ],
    remark: [
      { required: false, message: '原因必填', trigger: 'blur' },
    ],
  }
  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    this.classApi.list(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }

  private visible: boolean = false;
  private handleClose() {
    this.visible = false;
  }

  private onAdd() {
    this.add_form = new Util().clearObject(this.add_form);
    this.visible = true;
    this.buttonLoading = false
  }
  private onInputPhone(value:string){
    this.add_form.phone=value.replace(/^(0+)|[^\d]+/g,'')
  }
  private add_submit(): void {
    this.$refs['ruleForm'].validate((valid) => {
      if (valid) {
        this.buttonLoading = true
        this.classApi.add(this.add_form, (res: any) => {
          setTimeout(()=>{
            this.buttonLoading = false
          },500)
          Util.showToast("赠送成功");
          this.getTable()
          this.visible = false;
        },()=>{
          setTimeout(()=>{
            this.buttonLoading = false
          },500)
        })
      } else {
        return false;
      }
    });

  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getTable();
  }
  private tableData: any[] = [];
  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.getTable();
  }
}
