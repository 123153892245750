import {BasicsClass} from "@/common/BasicsClass";

export default class SingleStoreCardGiveAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/shopMember/memberGiveList',
            name: 'list',
            label: '列表'
        }],
        ['give', {
            url: '/shopMember/memberGive',
            name: 'give',
            label: '赠送'
        }],
    ])

    //单店卡赠送列表
    public list(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    // 单店卡赠送
    public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('give').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
